








import Vue, {VueConstructor} from 'vue';
import Component from 'vue-class-component';
import {createHelpers} from 'vuex-map-fields';
import {IMedia, MediaGroups, MediaType, MediaTypesGroups} from '@/services/api/media.service';
import { IDictionary } from '@/interfaces/interfaces';

import MediaStream from '@/pages/Public/Public.stream.vue';
import MediaError from '@/pages/Public/Public.error.vue';
import MediaIframe from '@/pages/Lti/LtiMedia.iframe.vue';

const { mapFields } = createHelpers({
  getterType: 'getMediaField',
  mutationType: 'updateMediaField',
});

@Component({
  components: {
    MediaStream,
    MediaIframe,
    MediaError
  },
  computed: {...mapFields([
      'sharedMedia'
    ]),
  }
})
export default class Public extends Vue {
  private sharedMedia!: IMedia | null;
  private mediaComponent: null | VueConstructor<Vue> = null;

  private componentDictionary: IDictionary<VueConstructor<Vue>> = {
    STREAM: MediaStream,
    IFRAME: MediaIframe
  }

  private mounted() {
    this.setData();
  }

  private setData() {
    const id = this.$route.params.id;
    this.$store.dispatch('getSharedMedia', id)
      .then(() => {
        if (!this.sharedMedia) return;
        const mediaGroup = MediaTypesGroups[this.sharedMedia.mediaType as MediaType];

        if (mediaGroup === MediaGroups.COLLECTION) {
          this.mediaComponent = this.componentDictionary.IFRAME;
          return;
        }

        this.mediaComponent = this.$store.getters.canMediaEmbed(this.sharedMedia)
          ? this.componentDictionary.STREAM
          : null;
    });
  }
}
