


























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import {IMedia, MediaTypes, MediaVideoExtensions, MediaAudioExtensions} from '@/services/api/media.service';
import LogoIcon from '@/assets/image/logo.svg';

@Component({
  components: {
    LogoIcon
  }
})

export default class PublicStream extends Vue {
  public MediaTypes = MediaTypes;
  public MediaVideoExtensions = MediaVideoExtensions;
  public MediaAudioExtensions = MediaAudioExtensions;
  
  @Prop({
    type: Object,
    required: true
  })
  public data!: IMedia;
}
